* {
    box-sizing: border-box;
}

html,
body,
#root {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Lexend', sans-serif;
    overscroll-behavior: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media screen and (orientation: landscape) {
    body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-color: #000;
        color: #fff;
        margin: 0;
    }
    #portrait {
        display: none;
    }
    #landscape {
        display: block;
        text-align: center;
        font-size: 24px;
    }
}

@media screen and (orientation: portrait) {
    #landscape {
        display: none;
    }
}

a {
    text-decoration: none !important;
    color: black !important;
    &:visited {
        color: black !important;
    }
    &:hover {
        color: black !important;
    }
    &:active {
        color: black !important;
    }
}
